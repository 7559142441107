import {
  EnumsH2HCardSource,
  EnumsH2HGameStarter,
  EnumsH2HJudgingMode,
  EnumsH2HJudgingSentiment,
  EnumsH2HJudgingUserGroup,
  EnumsH2HMode,
  EnumsH2HSelectPlayerStrategy,
  EnumsH2HSubTimerType,
  EnumsHiddenPictureAsymmetricPinDropAudibility,
  EnumsHiddenPictureAsymmetricPinDropVisibility,
  EnumsHiddenPicturePenaltyResetStrategy,
  EnumsSpotlightBlockStageLayout,
  ModelsBlockLifecycleRule,
  ModelsMediaAsset,
  ModelsTTSLabeledRenderSettings,
  ModelsTTSScript,
} from '@lp-lib/api-service-client/public';
import { VideoEffectsSettingsBlock } from './greenscreen';
import { Media, MediaData, MediaTranscodeStatus } from './media';
import { VoiceOver } from './voiceOver';

export enum BlockType {
  QUESTION = 'question',
  CREATIVE_PROMPT = 'creativePrompt',
  RAPID = 'rapid',
  SCOREBOARD = 'scoreboard',
  SPOTLIGHT = 'spotlight',
  SPOTLIGHT_V2 = 'spotlightV2',
  TEAM_RELAY = 'teamRelay',
  RANDOMIZER = 'randomizer',
  MULTIPLE_CHOICE = 'multipleChoice',
  MEMORY_MATCH = 'memoryMatch',
  PUZZLE = 'puzzle',
  ROUND_ROBIN_QUESTION = 'roundRobinQuestion',
  TITLE_V2 = 'titleV2',
  INSTRUCTION = 'instruction',
  OVERROASTED = 'overRoasted',
  DRAWING_PROMPT = 'drawingPrompt',
  HIDDEN_PICTURE = 'hiddenPicture',
  AI_CHAT = 'aiChat',
  GUESS_WHO = 'guessWho',
  ICEBREAKER = 'icebreaker',
  MARKETING = 'marketing',
  JEOPARDY = 'jeopardy',
  HEAD_TO_HEAD = 'headToHead',
}

export type BlockSummary = {
  id: string;
  type: BlockType;
  prettyTypeName: string;
  title?: string | null;
  subtitle?: string | null;
  coverMedia?: Media | null;
  submissionMedia?: Media | null;
};

export type NamedBlockAction =
  | 'replay-video'
  | 'townhall-to-crowd'
  | 'townhall-to-teams';

export type BlockActionWaitModeConfig = {
  wait: boolean;
  maxWaitDurationSec: number;
  exitable: boolean;
  exitableAfterSec: number;
  lastWaitBeforeEnd: boolean;
};

export interface BlockAction {
  gameSessionStatus?: number | null;
  timestamp: number;
  action?: NamedBlockAction | null;

  // NOTE: The following are not stored in the backend, but are derived at
  // runtime.
  waitMode?: BlockActionWaitModeConfig;
  // Only used for TitleCards: BreakIntoTeams
  durationMs?: number;
  voiceOver?: null | {
    mediaId: string;
    media: Media;
    mediaData: MediaData;
    delayStartMs?: number;
  };
}

type ISO8601String = string;

export interface BlockRecording {
  mediaId: string | null;
  media?: Media | null;
  actions: BlockAction[];
  durationMs: number;
  version: number;
  updatedByUid: string; // user uuid id
  updatedAt: ISO8601String;
}

type BlockBase = {
  id: string;
  type: BlockType;
  position: number;
  gameId: string;
  brandId?: string | null;
  createdAt: ISO8601String;
  updatedAt: ISO8601String;
  fields: unknown;
  outdatedRecording: boolean;
  recording?: BlockRecording | null;
  videoEffectsSettings?: VideoEffectsSettingsBlock | null;
  approximateDurationSeconds: number;
};

export type BlockFieldsShared = {
  title: string | null;
  internalLabel: string;
  ugcAssetId?: string | null;
  ugcPromptTemplateId?: string | null;
  notes?: string | null;
  lifecycleRules?: ModelsBlockLifecycleRule[] | null;
};

export type RapidBlockMedia = {
  mediaFields: {
    questionMedia: Media | null;
    answerMedia: Media | null;
  };
  mediaIdFields: {
    questionMediaData: MediaData | null;
    answerMediaData: MediaData | null;
  };
};

export type RapidCorrectAnswer = {
  answer: string;
  points: number;
};

export type RapidBlock = BlockBase & {
  type: BlockType.RAPID;
  fields: BlockFieldsShared & {
    question: string;
    questionTime: number;
    // This is actually a string JSON representation of
    // JSON.parse(fields.answers) -> RapidCorrectAnswer[]
    answers: string;
    scoreboard: boolean;
    everyoneSubmits: boolean;
    showMissedAnswers: boolean;
    startVideoWithTimer: boolean;
  } & RapidBlockMedia['mediaFields'] &
    RapidBlockMedia['mediaIdFields'];
};

export type CreativePromptBlockMedia = {
  mediaFields: {
    submissionMedia: Media | null;
  };
  mediaIdFields: {
    submissionMediaData: MediaData | null;
  };
};

export type CreativePromptBlock = BlockBase & {
  type: BlockType.CREATIVE_PROMPT;
  fields: BlockFieldsShared & {
    prompt: string;
    submissionTime: number;
    votingTime: number;
    points: number;
    scoreboard: boolean;
    startVideoWithTimer: boolean;
    pointsMultiplier: number;
  } & CreativePromptBlockMedia['mediaFields'] &
    CreativePromptBlockMedia['mediaIdFields'];
};

export type QuestionBlockMedia = {
  mediaFields: {
    questionMedia: Media | null;
    answerMedia: Media | null;
  };
  mediaIdFields: {
    questionMediaData: MediaData | null;
    answerMediaData: MediaData | null;
  };
};

export type QuestionBlock = BlockBase & {
  type: BlockType.QUESTION;
  fields: BlockFieldsShared & {
    question: string;
    answer: string;
    additionalAnswers: string;
    time: number;
    points: number;
    displayPointsMultiplier: boolean;
    scoreboard?: boolean;
    decreasingPointsTimer?: boolean;
    startVideoWithTimer?: boolean;
    startDescendingImmediately?: boolean;
  } & QuestionBlockMedia['mediaFields'] &
    QuestionBlockMedia['mediaIdFields'];
};

export type MultipleChoiceBlockMedia = {
  mediaFields: {
    questionMedia: Media | null;
    backgroundMedia: Media | null;
    answerMedia: Media | null;
  };
  mediaIdFields: {
    questionMediaData: MediaData | null;
    backgroundMediaId: string | null;
    answerMediaData: MediaData | null;
  };
};

export type MultipleChoiceOption = {
  text: string;
  mediaId?: string | null;
  media?: Media | null;
  correct?: boolean;
};

export type MultipleChoiceBlock = BlockBase & {
  type: BlockType.MULTIPLE_CHOICE;
  fields: BlockFieldsShared & {
    question: string;
    answerChoices: MultipleChoiceOption[];
    questionTimeSec: number;
    points: number;
    displayPointsMultiplier: boolean;
    decreasingPointsTimer: boolean;
    startVideoWithTimer: boolean;
    startDescendingImmediately?: boolean;
  } & MultipleChoiceBlockMedia['mediaFields'] &
    MultipleChoiceBlockMedia['mediaIdFields'];
};

export type ScoreboardBlockMedia = {
  mediaFields: Record<string, Media | null>;
  mediaIdFields: Record<string, string | null>;
};

export enum ScoreboardMode {
  VenueTeams = 1,
  GlobalTeams = 2,
  OrgTeams = 3,
  VenueGlobalTeams = 4,
}

export const SCOREBOARD_MODE_DEFAULT = ScoreboardMode.VenueTeams;

export type ScoreboardBlock = BlockBase & {
  type: BlockType.SCOREBOARD;
  fields: BlockFieldsShared & {
    mode: ScoreboardMode;
    voiceOver?: VoiceOver | null;
    ttsOptions?: ModelsTTSLabeledRenderSettings[] | null;
    ttsScripts?: ModelsTTSScript[] | null;
  };
};

export type SpotlightBlockMedia = {
  mediaFields: {
    backgroundMedia: Media | null;
    overlayMedia: Media | null;
  };
  mediaIdFields: {
    backgroundMediaData: MediaData | null;
    overlayMediaId: string | null;
  };
};

export type SpotlightBlock = BlockBase & {
  type: BlockType.SPOTLIGHT;
  fields: BlockFieldsShared & {
    message: string;
    preselectedTeamOrder: number;
    voiceOver?: VoiceOver | null;
    playConfetti: boolean;
    stageLayout?: EnumsSpotlightBlockStageLayout | null;
    ttsOptions?: ModelsTTSLabeledRenderSettings[] | null;
    ttsScripts?: ModelsTTSScript[] | null;
  } & SpotlightBlockMedia['mediaFields'] &
    SpotlightBlockMedia['mediaIdFields'];
};

export type SpotlightBlockV2 = BlockBase & {
  type: BlockType.SPOTLIGHT_V2;
  fields: BlockFieldsShared & {
    message: string;
    preselectedTeamOrder: number;
    votingMode: boolean;
    votingPoints: number;
    instantWinnerPoints: number;
    voiceOver?: VoiceOver | null;
    ttsOptions?: ModelsTTSLabeledRenderSettings[] | null;
    ttsScripts?: ModelsTTSScript[] | null;
  } & SpotlightBlockMedia['mediaFields'] &
    SpotlightBlockMedia['mediaIdFields'];
};

export enum TeamRelayBlockMode {
  MovingTowardsGoal = 1,
}

export type TeamRelayBlockMedia = {
  mediaFields: {
    introMedia: Media | null;
    outroMedia: Media | null;
    backgroundMedia: Media | null;
    movingObjectMedia: Media | null;
    stationaryGoalMedia: Media | null;
    goalAnimationMedia: Media | null;
  };
  mediaIdFields: {
    introMediaData: MediaData | null;
    outroMediaData: MediaData | null;
    backgroundMediaData: MediaData | null;
    movingObjectMediaId: string | null;
    stationaryGoalMediaId: string | null;
    goalAnimationMediaData: MediaData | null;
  };
};

export type TeamRelayBlock = BlockBase & {
  type: BlockType.TEAM_RELAY;
  fields: BlockFieldsShared & {
    text: string;
    mode: TeamRelayBlockMode;
    points: number;
    sequenceTime: number;
    difficultyLevel: number;
    decreasingPointsTimer: boolean;
    startDescendingImmediately?: boolean;
  } & TeamRelayBlockMedia['mediaFields'] &
    TeamRelayBlockMedia['mediaIdFields'];
};

export const RandomizerBlockTierIndexValues = [1, 2, 3, 4, 5] as const;
export type RandomizerBlockTierIndex =
  (typeof RandomizerBlockTierIndexValues)[number];

export type RandomizerBlock = BlockBase & {
  type: BlockType.RANDOMIZER;
  fields: BlockFieldsShared & {
    [K in RandomizerBlockTierIndex as `t${RandomizerBlockTierIndex}TeamSize`]: number;
  } & {
    [T in RandomizerBlockTierIndex as `t${RandomizerBlockTierIndex}TeamSizeMax`]: number;
  } & {
    timer: number;
    oneTeam: boolean;
  };
};

export type MemoryMatchBlockMedia = {
  mediaFields: {
    backgroundMedia: Media | null;
    goalAnimationMedia: Media | null;
  };
  mediaIdFields: {
    backgroundMediaData: MediaData | null;
    goalAnimationMediaData: MediaData | null;
  };
};

export type MemoryMatchCardPair = {
  firstMediaId?: string | null;
  firstMedia?: Media | null;
  secondMediaId?: string | null;
  secondMedia?: Media | null;
};

export type MemoryMatchBlock = BlockBase & {
  type: BlockType.MEMORY_MATCH;
  fields: BlockFieldsShared & {
    text: string;
    numberOfCardPairs: number;
    cardPairs: MemoryMatchCardPair[];
    pointsPerMatch: number;
    gameTimeSec: number;
    decreasingPointsTimer: boolean;
    startDescendingImmediately?: boolean;
    hiddenGameplay: boolean;
    /**
     * @deprecated this is no longer used
     */
    realtimeLeaderboard: boolean;
  } & MemoryMatchBlockMedia['mediaFields'] &
    MemoryMatchBlockMedia['mediaIdFields'];
};

export type PuzzleBlockMedia = {
  mediaFields: {
    introMedia: Media | null;
    backgroundMedia: Media | null;
    goalAnimationMedia: Media | null;
    outroMedia: Media | null;
  };
  mediaIdFields: {
    introMediaData: MediaData | null;
    backgroundMediaData: MediaData | null;
    goalAnimationMediaData: MediaData | null;
    outroMediaData: MediaData | null;
  };
};

export type PuzzlePiece = {
  id: string;
  value: string;
  mediaId?: string | null;
  media?: Media | null;
};

export type PuzzleDropSpot = {
  acceptedValue: string;
  mediaId?: string | null;
  media?: Media | null;
};

export type GridSize = {
  numRows: number;
  numCols: number;
};

export enum PuzzleMode {
  Puzzle = 'puzzle',
  DragAndDrop = 'drag_and_drop',
}

export type PuzzleSliceJob = {
  id: string;
  status: MediaTranscodeStatus;
  gridSize: GridSize;
  sourceMediaData?: MediaData | null;
  sourceMedia?: Media | null;
};

export type PuzzleBlock = BlockBase & {
  type: BlockType.PUZZLE;
  fields: BlockFieldsShared & {
    text: string;
    mode?: PuzzleMode;
    gridSize: GridSize;
    pieces: PuzzlePiece[];
    dropSpots: PuzzleDropSpot[];
    pointsPerCorrectPiece: number;
    completionBonusPoints: number;
    gameTimeSec: number;
    decreasingPointsTimer: boolean;
    gradeOnPlacement: boolean;
    startDescendingImmediately?: boolean;
    showCompletionProgress?: boolean;
    sliceJob?: PuzzleSliceJob | null;
    showPreview?: boolean;
  } & PuzzleBlockMedia['mediaFields'] &
    PuzzleBlockMedia['mediaIdFields'];
};

export enum RoundRobinMode {
  Default = 'default',
  Race = 'race',
}

export type RoundRobinQuestionBlockMedia = {
  mediaFields: {
    backgroundMedia: Media | null;
    goalAnimationMedia: Media | null;
    raceUnitMedia: Media | null;
  };
  mediaIdFields: {
    backgroundMediaData: MediaData | null;
    goalAnimationMediaData: MediaData | null;
    raceUnitMediaData: MediaData | null;
  };
};

export type RoundRobinClue = {
  clueId: string;
  text: string;
  points: number;
  used?: boolean;
  title?: string | null;
};

export type RoundRobinIncorrectAnswer = {
  id: string;
  text: string;
};

export type RoundRobinAnswerOrder = {
  randomize: boolean;
  orderedAnswerIds: string[];
};

export type RoundRobinQuestion = {
  question: string;
  questionMediaId?: string | null;
  questionMediaLoop: boolean;
  questionMedia?: Media | null;
  answers: string;
  answerMediaId?: string | null;
  answerMedia?: Media | null;
  incorrectAnswers?: RoundRobinIncorrectAnswer[];
  answerOrder: RoundRobinAnswerOrder | null;
  backgroundMediaId?: string | null;
  backgroundMedia?: Media | null;
  timeSec: number;
  points: number;
  createdAt: number;
  clues?: RoundRobinClue[];
};

export type RoundRobinQuestionBlock = BlockBase & {
  type: BlockType.ROUND_ROBIN_QUESTION;
  fields: BlockFieldsShared & {
    questions: RoundRobinQuestion[];
    gameTimeSec: number;
    clueTimeSec: number;
    decreasingPointsTimer: boolean;
    incorrectAnswerPenalty: boolean;
    randomizeQuestions: boolean;
    rapidSubmissions: boolean;
    hotSeatUI: boolean;
    replayIncorrectQuestions: boolean;
    questionsSkippable: boolean;
    startDescendingImmediately?: boolean;
    mode?: RoundRobinMode;
    raceWinPercentage?: number;
    muteBackgroundMusic?: boolean;
    hideAnswerField?: boolean;
  } & RoundRobinQuestionBlockMedia['mediaFields'] &
    RoundRobinQuestionBlockMedia['mediaIdFields'];
};

export type TTSPlaybackOptions = {
  id: string;
  delayMs: number;
  tags: string[];
};

export type TitleBlockV2Media = {
  mediaFields: Record<string, Media | null>;
  mediaIdFields: Record<string, string | null>;
};

export type TitleV2TTSScriptTags =
  | 'conclusion'
  | 'individual-team'
  | 'introduction'
  | 'runtime';

export type TitleCard = {
  id: string;
  mediaData?: MediaData | null;
  media?: Media | null;
  text: string;
  voiceOver?: VoiceOver | null;
  fullscreen: boolean;
  playBackgroundMusicWithMedia: boolean;
  animatedTransition: boolean;
  teamIntroEnabled?: boolean | null;
  breakIntoTeams?: boolean | null;
};

export type TitleBlockV2 = BlockBase & {
  type: BlockType.TITLE_V2;
  fields: BlockFieldsShared & {
    cards: TitleCard[] | null;
    ttsOptions?: ModelsTTSLabeledRenderSettings[] | null;
    ttsScripts?: ModelsTTSScript[] | null;
    ttsPlaybackOptions?: TTSPlaybackOptions[] | null;
    waitAfterEachCardSec: number;
  };
};

export const INSTRUCTION_MAX_DISPLAY_SECONDS = 120;

export type InstructionBlockMedia = {
  mediaFields: Record<string, Media | null>;
  mediaIdFields: Record<string, string | null>;
};

export type InstructionCard = {
  id: string;
  mediaData?: MediaData | null;
  media?: Media | null;
  text: string;
};

export type InstructionBlock = BlockBase & {
  type: BlockType.INSTRUCTION;
  fields: BlockFieldsShared & {
    cards: InstructionCard[] | null;
  };
};

export type OverRoastedBlock = BlockBase & {
  type: BlockType.OVERROASTED;
  fields: BlockFieldsShared & {
    trucksCount: number;
    dispensersCountPerTruck: number;
    maxIngredientsPerPlayer: number;
    maxIngredientsPerOrder: number;
    gameTimeSec: number;
    pointsPerOrder: number;
    tutorialMode: boolean;
  } & OverRoastedBlockMedia['mediaFields'] &
    OverRoastedBlockMedia['mediaIdFields'];
};

export type OverRoastedBlockMedia = {
  mediaFields: {
    introMedia: Media | null;
    outroMedia: Media | null;
  };
  mediaIdFields: {
    introMediaData: MediaData | null;
    outroMediaData: MediaData | null;
  };
};

export type DrawingPromptBlockMedia = {
  mediaFields: {
    backgroundMedia: Media | null;
    canvasMedia: Media | null;
  };
  mediaIdFields: {
    backgroundMediaData: MediaData | null;
    canvasMediaData: MediaData | null;
  };
};

export type DrawingPrompt = {
  id: string;
  correct: string;
};

export type DrawingPromptBlock = BlockBase & {
  type: BlockType.DRAWING_PROMPT;
  fields: BlockFieldsShared & {
    prompts: DrawingPrompt[];
    drawingTimeSec: number;
    votingTimeSec: number;
    correctPromptPoints: number;
    incorrectPromptPoints: number;
  } & DrawingPromptBlockMedia['mediaFields'] &
    DrawingPromptBlockMedia['mediaIdFields'];
};

export type HiddenPictureBlockMedia = {
  mediaFields: {
    backgroundMedia: Media | null;
    introMedia: Media | null;
    outroMedia: Media | null;
    successMedia: Media | null;
    successAudioMedia: Media | null;
    failMedia: Media | null;
    failAudioMedia: Media | null;
    gameCompletionMedia: Media | null;
    penaltiesFailureMedia: Media | null;
  };
  mediaIdFields: {
    backgroundMediaData: MediaData | null;
    introMediaData: MediaData | null;
    outroMediaData: MediaData | null;
    successMediaData: MediaData | null;
    successAudioMediaData: MediaData | null;
    failMediaData: MediaData | null;
    failAudioMediaData: MediaData | null;
    gameCompletionMediaData: MediaData | null;
    penaltiesFailureMediaData: MediaData | null;
  };
};

export type HotSpot = {
  id: string;
  name: string;
  points: number;
  top: number;
  left: number;
  radius: number;
};

export type HiddenPicture = {
  id: string;
  name: string;
  question: string;
  sequenced: boolean;
  everyoneClicks: boolean;
  hotSpots?: HotSpot[];
  tool: 'none' | 'magnifier' | 'flashlight' | 'unblur';
  incorrectAnswerPenalty: number;
  mainMedia: Media | null;
  mainMediaData: MediaData | null;
  asymmetricGamePlay: boolean;
  asymmetricMedia?: Media | null;
  asymmetricMediaData?: MediaData | null;
  asymmetricPinDropVisibility?: EnumsHiddenPictureAsymmetricPinDropVisibility | null;
  asymmetricPinDropAudibility?: EnumsHiddenPictureAsymmetricPinDropAudibility | null;
};

export type HiddenPictureBlock = BlockBase & {
  type: BlockType.HIDDEN_PICTURE;
  fields: BlockFieldsShared & {
    pictures: HiddenPicture[] | null | undefined;
    randomizePictures: boolean;
    showItemList: boolean;
    gameTimeSec: number;
    maxPenaltyLimit?: number | null;
    maxPenaltyLimitLabel?: string | null;
    penaltyResetStrategy?: EnumsHiddenPicturePenaltyResetStrategy | null;
    bonusPointsPerRemainingSec: number;
  } & HiddenPictureBlockMedia['mediaFields'] &
    HiddenPictureBlockMedia['mediaIdFields'];
};

export type AIChatBlockMedia = {
  mediaFields: {
    introMedia: Media | null;
    outroMedia: Media | null;
    backgroundMedia: Media | null;
    winMedia: Media | null;
    loseMedia: Media | null;
  };
  mediaIdFields: {
    introMediaData: MediaData | null;
    outroMediaData: MediaData | null;
    backgroundMediaData: MediaData | null;
    winMediaData: MediaData | null;
    loseMediaData: MediaData | null;
  };
};

export type AIChatBot = {
  enabled: boolean;
  name: string;
  avatarMedia?: Media | null;
  avatarMediaData?: MediaData | null;
};

export type AIChatBlock = BlockBase & {
  type: BlockType.AI_CHAT;
  fields: BlockFieldsShared & {
    model?: string | null;
    temperature: number;
    systemPrompt: string;
    systemPromptTemperature?: number | null;
    gameTimeSec: number;
    winningPoints: number;
    showResults: boolean;
    bot: AIChatBot;
    promptTemplateId?: string | null;
    roundRobinMode: boolean;
    decreasingPointsTimer: boolean;
    startDescendingImmediately?: boolean;
  } & AIChatBlockMedia['mediaFields'] &
    AIChatBlockMedia['mediaIdFields'];
};

export type GuessWhoBlockMedia = {
  mediaFields: {
    introMedia: Media | null;
    backgroundMedia: Media | null;
  };
  mediaIdFields: {
    introMediaData: MediaData | null;
    backgroundMediaData: MediaData | null;
  };
};

export type GuessWhoPrompt = {
  id: string;
  text: string;
};

export type GuessWhoBlock = BlockBase & {
  type: BlockType.GUESS_WHO;
  fields: BlockFieldsShared & {
    prompts: GuessWhoPrompt[];
    promptTimeSec: number;
    pointsPerCorrect: number;
    showGuessers: boolean;
  } & GuessWhoBlockMedia['mediaFields'] &
    GuessWhoBlockMedia['mediaIdFields'];
};

export type IcebreakerBlockMedia = {
  mediaFields: {
    backgroundMedia: Media | null;
  };
  mediaIdFields: {
    backgroundMediaData: MediaData | null;
  };
};

export enum IcebreakerOnStageSelection {
  None = 0,
  Random = 1,
  VIP = 2,
}

export enum IcebreakerSelectNextStrategy {
  Default = 0,
  OnStageChoose = 1,
  KeepCurrent = 2,
}

export enum IcebreakerOnStageTimerMode {
  PlayerReset = 0,
  CardReset = 1,
}

export type IcebreakerOption = {
  text: string;
  onStageBackground?: ModelsMediaAsset | null;
  hiddenToAudienceText?: string | null;
  audienceBackground?: ModelsMediaAsset | null;
};

export type IcebreakerCard = {
  id: string;
  texts: string[];
  options: IcebreakerOption[];
  hiddenToAudience: boolean;
};

export enum IcebreakerMode {
  Default = 'default',
  HeadsUp = 'headsUp',
  ChatPack = 'chatPack',
}

export type IcebreakerBlock = BlockBase & {
  type: BlockType.ICEBREAKER;
  fields: BlockFieldsShared & {
    gameName: string;
    mode?: IcebreakerMode;
    gameTimeSec: number;
    instructionsOnStage: string;
    instructionsNotOnStage: string;
    cards: IcebreakerCard[];
    onStageSelection: IcebreakerOnStageSelection;
    selectNextStrategy: IcebreakerSelectNextStrategy;
    onStageCardSelection: boolean;
    cardClickProgressesGame: boolean;
    offStageCardVoting: boolean;
    muteBackgroundMusic: boolean;
    skipGameRecap: boolean;
    playCardsInOrder: boolean;
    onStageTimeSec: number;
    onStageTimerMode: IcebreakerOnStageTimerMode;
    onStageTimerAutoStart: boolean;
    points: number;
  } & IcebreakerBlockMedia['mediaFields'] &
    IcebreakerBlockMedia['mediaIdFields'];
};

export type MarketingBlock = BlockBase & {
  type: BlockType.MARKETING;
  fields: BlockFieldsShared & {
    skippableAfterSec: number;
  };
};

export type JeopardyClue = {
  id: string;
  question: string;
  answer: string;
  value: number;
};

export type JeopardyCategory = {
  id: string;
  name: string;
  nameScript?: string | null;
  clues: JeopardyClue[];
};

export type JeopardyBoard = {
  categories?: JeopardyCategory[] | null;
};

export type JeopardyBlockMedia = {
  mediaFields: {
    backgroundMedia: Media | null;
    outroMedia: Media | null;
  };
  mediaIdFields: {
    backgroundMediaData: MediaData | null;
    outroMediaData: MediaData | null;
  };
};

export type JeopardyMediaLookupKey =
  | `${string}-category`
  | `${string}-question`
  | `host-script-${string}`;

export type JeopardyMediaLookup = {
  [key in JeopardyMediaLookupKey]?: {
    mediaData: MediaData;
    media?: Media | null;
  };
};

export type JeopardyBlock = BlockBase & {
  type: BlockType.JEOPARDY;
  fields: BlockFieldsShared & {
    boardSize: GridSize;
    board: JeopardyBoard;
    clueSelectionTimeSec: number;
    buzzerTimeSec: number;
    answerTimeSec: number;
    answerPrepareTimeSec: number;
    judgingTimeSec: number;
    ttsOptions?: ModelsTTSLabeledRenderSettings[] | null;
    ttsScripts?: ModelsTTSScript[] | null;
    mediaLookup?: JeopardyMediaLookup | null;
  } & JeopardyBlockMedia['mediaFields'] &
    JeopardyBlockMedia['mediaIdFields'];
};

export type HeadToHeadCardPrompt = {
  source: EnumsH2HCardSource;
  text: string;
  cover: ModelsMediaAsset | null;
  voiceOver: VoiceOver | null;
};

export type HeadToHeadCard = {
  id: string;
  default: HeadToHeadCardPrompt | null;
  audience: HeadToHeadCardPrompt | null;
  groupA: HeadToHeadCardPrompt | null;
  groupB: HeadToHeadCardPrompt | null;
};

export type HeadToHeadBlock = BlockBase & {
  type: BlockType.HEAD_TO_HEAD;
  fields: BlockFieldsShared & {
    gameName: string;
    gamePrompt: string;
    gameMode: EnumsH2HMode;
    introductoryVoiceOver: VoiceOver | null;
    introMedia: ModelsMediaAsset | null;
    gameTimeSec: number;
    subTimerType: EnumsH2HSubTimerType;
    subTimeSec: number;
    selectPlayerStrategy: EnumsH2HSelectPlayerStrategy;
    background: ModelsMediaAsset | null;
    gameStarter: EnumsH2HGameStarter;
    cardSkippable: boolean;
    replayable: boolean;
    showResults: boolean;
    judgingUserGroup: EnumsH2HJudgingUserGroup;
    judgingHeadline: string;
    judgingPrompt: string;
    judgingAfterXTurns: number;
    judgingWhenTimesUp: boolean;
    judgingPoints: number;
    judgingVoiceOver: VoiceOver | null;
    judgingMode: EnumsH2HJudgingMode;
    judgingInGameButtonText: string;
    judgingInGameSendingEmoji: string;
    judgingInGameAfterXTurns: number;
    judgingInGameSentiment: EnumsH2HJudgingSentiment;
    cards: HeadToHeadCard[];
    ttsOptions?: ModelsTTSLabeledRenderSettings[] | null;
    ttsScripts?: ModelsTTSScript[] | null;
  };
};

export type Block =
  | QuestionBlock
  | CreativePromptBlock
  | RapidBlock
  | ScoreboardBlock
  | SpotlightBlock
  | SpotlightBlockV2
  | TeamRelayBlock
  | RandomizerBlock
  | MultipleChoiceBlock
  | MemoryMatchBlock
  | PuzzleBlock
  | RoundRobinQuestionBlock
  | TitleBlockV2
  | InstructionBlock
  | OverRoastedBlock
  | DrawingPromptBlock
  | HiddenPictureBlock
  | AIChatBlock
  | GuessWhoBlock
  | IcebreakerBlock
  | MarketingBlock
  | JeopardyBlock
  | HeadToHeadBlock;

export type BlockFields<T extends Block = Block> = T['fields'];

export type BlockMedia =
  | QuestionBlockMedia
  | CreativePromptBlockMedia
  | RapidBlockMedia
  | ScoreboardBlockMedia
  | SpotlightBlockMedia
  | TeamRelayBlockMedia
  | MultipleChoiceBlockMedia
  | MemoryMatchBlockMedia
  | PuzzleBlockMedia
  | RoundRobinQuestionBlockMedia
  | TitleBlockV2Media
  | InstructionBlockMedia
  | OverRoastedBlockMedia
  | DrawingPromptBlockMedia
  | HiddenPictureBlockMedia
  | GuessWhoBlockMedia
  | IcebreakerBlockMedia;

export type BlockMediaFields<T extends BlockMedia = BlockMedia> =
  T['mediaFields'];

export type BlockMediaIdFields<T extends BlockMedia = BlockMedia> =
  T['mediaIdFields'];

/**
 * Avoid this type, as it is the intersection, aka cartesian product/join, of
 * all fields regardless of the Block type. This is an unsafe operation since
 * there is nothing tying a particular field to the subject block. This was
 * mostly needed to allow redux + localStorage caching to compile.
 * @deprecated
 */
export type BlockFieldsIntersection = BlockFields<QuestionBlock>;

export interface SpotlightBlockPreconfig {
  selectedUserIds: string[];
}

export type BlockPreconfig = SpotlightBlockPreconfig;
